import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

import Video from "../components/video"

const PPOPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
      <Layout title={siteTitle}>
        <SEO title="Pleasant Pointe Observatory" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

        <article className="post-content page-template no-image">
          <div className="post-content-body">
            <h2 id="Heading">
              Pleasant Pointe Observatory
            </h2>
            <figure className="kg-card kg-image-card kg-width-wide">
              <Img
                  fluid={data.ppoRoofClosed.childImageSharp.fluid}
                  className="kg-image"
              />
              <figcaption>The observatory with the roof closed.</figcaption>
            </figure>
            <figure className="kg-card kg-image-card kg-width-wide">
              <Img
                  fluid={data.ppoRoofOpen.childImageSharp.fluid}
                  className="kg-image"
              />
              <figcaption>The observatory with the roof open.</figcaption>
            </figure>
            <hr />
            <figure className="kg-card kg-width-wide">
              <div className="video-container">
                <Video
                    videoSrcURL="https://www.youtube.com/embed/8UddJsJtTek"
                    videoTitle="Pleasant Pointe Observatory - Fall 2010"
                />
              </div>
            </figure>
            <hr />

            <figure className="kg-card kg-image-card">
              <Img
                  fluid={data.ppoScopes.childImageSharp.fluid}
                  className="kg-image"
              />
              <figcaption>14" Meade LX200 with piggybacked William Optics 110mm Refractor</figcaption>
            </figure>

          </div>
        </article>
      </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    ppoRoofClosed: file(
      relativePath: { eq: "ppo-day-roof-closed.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ppoRoofOpen: file(
      relativePath: { eq: "ppo-day-roof-open.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ppoScopes: file(
      relativePath: { eq: "ppo_MeadeLX200_WO110.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
    <StaticQuery
        query={indexQuery}
        render={data => (
            <PPOPage location={props.location} data={data} {...props} />
        )}
    />
)
